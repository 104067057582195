import styles from '../styles/MissionItem.module.css'

import Flex from './playbook/Flex'
import Title from './playbook/Title'

type MissionItemProps = {
  title: string,
  icon: string
}
const MissionItem = ({ title, icon }: MissionItemProps) => {
  return (
    <Flex align="center" className={styles.missionItem} orientation="column" padding="xs" >
      <Flex align="center" justify="center" className={styles.missionItemIconContainer} >
        <i className={`far ${icon} ${styles.missionIcon}`} />
      </Flex>
      <Title tag="h4" text={title} size={4} marginTop="xs" className={styles.missionItemTitle} />
    </Flex>
  );
};

export default MissionItem;
