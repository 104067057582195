import styles from '../../styles/Leadership.module.css'

const LeadershipSVG = () => (
  <svg
    className={styles.leadershipSvg}
    viewBox="0 0 380 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      fontFamily="Venera700, Venera"
      fontSize="36"
      fontWeight="bold"
      letterSpacing="-0.4"
    >
      <g
        transform="translate(-576.000000, -2158.000000)"
        fill="#FFFFFF"
      >
        <text>
          <tspan x="573.052" y="2183">
            Leadership
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

export default LeadershipSVG;
