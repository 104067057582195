import styles from '../../styles/Hero.module.css'

const BrandNameSVG = () => (
  <svg
    className={styles.brandSvg}
    viewBox="0 0 499 59"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      fontFamily="Venera700, Venera"
      fontSize="84"
      fontWeight="bold"
      letterSpacing="-0.933333333"
    >
      <g
        id="Homepage-Copy"
        transform="translate(-476.000000, -203.000000)"
        fill="#09ADB9"
      >
        <text id="Stream">
          <tspan x="472.426" y="262">
            Stream
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

export default BrandNameSVG;
