import styles from '../styles/Founder.module.css'

import Body from './playbook/Body'
import Flex from './playbook/Flex'
import Title from './playbook/Title'

type FounderProps = {
  description: string
  image: string
  name: string
  title: string
}

const Founder = (props: FounderProps) => {
  const { description, image, name, title } = props;
  return (
    <Flex orientation="column" className={styles.founderCard} padding="sm">
      <img src={image} className={styles.founderCardImage} alt={name} />
      <Title tag="h4" marginBottom="xs" marginTop="sm" text={name} className={styles.founderCardName} />
      <Title tag="h5" marginBottom="xs" text={title} className={styles.founderCardTitle} />
      <Body className={styles.founderCardDescription} >{description}</Body>
    </Flex>
  );
};

export default Founder;
