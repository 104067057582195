import MissionItem from "./MissionItem";
import TitleBorder from "./TitleBorder"
import MissionSVG from "./svgs/mission-svg.js";
import Flex from "../components/playbook/Flex"

const mission = [
  {
    title: "Energy Efficiency",
    icon: "fa-leaf",
  },
  {
    title: "Increase Home Value",
    icon: "fa-chart-line",
  },
  {
    title: "Energy Savings",
    icon: "fa-piggy-bank",
  },
  {
    title: "Community Enhancement",
    icon: "fa-users",
  }
];

const Mission = () => (
  <section className="mission">
    <Flex paddingX="md" paddingY="xl" orientation="column" align="center">
      <Flex marginBottom="lg" marginTop="xl" justify="center" align="center">
        <TitleBorder>
          {MissionSVG()}
        </TitleBorder>
      </Flex>
      <Flex justify="center" marginBottom="xl" wrap>
        {mission.map((value) => (
          <MissionItem
            icon={value.icon}
            title={value.title}
            key={value.title}
          />
        ))}
      </Flex>
    </Flex>
  </section>
);

export default Mission;
