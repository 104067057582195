import "../../styles/globals.css"
import "../../styles/fonts.css"
import "playbook-ui/dist/playbook.css"
import "playbook-ui/dist/reset.css"
import "@fortawesome/fontawesome-free/js/fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faChevronDoubleDown, faLeaf, faUsers, faPiggyBank, faChartLine } from "@fortawesome/pro-regular-svg-icons"
import StreamInnovations from "../../components/StreamInnovations"

library.add(faChevronDoubleDown, faLeaf, faUsers, faPiggyBank, faChartLine)

type HomeProps = {
  domain: string
}

const Home = ({ domain }: HomeProps) => {
  return (
    <>
      <StreamInnovations domain={domain} />
    </>
  )
}

export default Home
