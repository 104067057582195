import styles from '../../styles/Mission.module.css'

const MissionSVG = (): JSX.Element => (
  <svg
    className={styles.missionSvg}
    viewBox="0 0 380 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      fontFamily="Venera700, Venera"
      fontSize="36"
      fontWeight="bold"
      letterSpacing="-0.4"
    >
      <g
        transform="translate(-537.000000, -3014.000000)"
        fill="#242B42"
      >
        <text>
          <tspan x="535.902" y="3039">
            Our Mission
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

export default MissionSVG;
