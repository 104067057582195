import styles from "../styles/Hero.module.css"
import Header from "./Header";
import BrandNameSVG from "./svgs/brand-name-svg.js";
import BrandIntroSVG from "./svgs/brand-intro-svg.js";

import Flex from '../components/playbook/Flex'
import Title from '../components/playbook/Title'

type HeroProps = {
  domain: string
}

const Hero = (props: HeroProps) => (
  <section className={styles.hero} >
    <Header domain={props.domain} />
    <Flex
      align="center"
      orientation="column"
      marginY="xl"
      paddingX="xs"
      paddingY="xl"
    >
      <Flex
        align="center"
        className="w-45"
        justify="center"
      >
        {BrandIntroSVG()}
      </Flex>
      <Flex
        align="center"
        className="w-45"
        justify="center"
        marginBottom="md"
        marginTop="sm"
      >
        {BrandNameSVG()}
      </Flex>
      <Title tag="h4" size={4} className={styles.heroSubHeader}>
        A financial services company in the energy efficiency home remodeling
        space
      </Title>
    </Flex>

    <div className={styles.heroLead}>
      <img src="/images/stream-lead.png" className={styles.heroLeadImage} />
    </div>

    <Flex marginTop="xl" padding="xl" justify="center">
      <i className={`far fa-chevron-double-down ${styles.scrollIcon}`} />
    </Flex>
  </section>
);

export default Hero;
