
import styles from '../../styles/Hero.module.css'

const BrandIntroSVG = () => (
  <svg
    className={styles.introSvg}
    viewBox="0 0 155 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      fontFamily="Venera700, Venera"
      fontSize="33"
      fontWeight="bold"
      letterSpacing="-0.377777778"
    >
      <g
        transform="translate(-641.000000, -166.000000)"
        fill="#FFFFFF"
      >
        <text>
          <tspan x="641.160222" y="190">
            This is
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

export default BrandIntroSVG;
