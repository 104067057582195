import Footer from "./Footer"
import Hero from "./Hero"
import Opportunities from "./Opportunities"
import Leadership from "./Leadership"
import Mission from "./Mission"

type StreamInnovationsProps = {
  domain: string
}

const StreamInnovations = ({ domain }: StreamInnovationsProps) => {
  return (
    <>
      <Hero domain={domain} />
      <Opportunities domain={domain} />
      <Leadership domain={domain} />
      <Mission />
      <Footer domain={domain} />
    </>
  )
}

export default StreamInnovations
