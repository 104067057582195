import styles from "../styles/Footer.module.css";

import Address from "../components/playbook/Address"
import Button from "../components/playbook/Button"
import Body from "../components/playbook/Body"
import Flex from "../components/playbook/Flex"
import FlexItem from "../components/playbook/FlexItem"

type FooterProps = {
  domain: string
}

const Footer = (props: FooterProps) => (
  <footer>
    <Flex align="start" className={styles.hero} padding="lg" gap="lg" wrap>
      <FlexItem paddingX="lg">
        <img
          src={props.domain == "streaminnovations" ? "/images/stream-innovations-logo-dark-bg.png" : "/images/stream-financial-logo.png"}
          className={styles.footerLogo}
          width="120"
        />
      </FlexItem>
      <FlexItem grow>
        <Flex wrap align="stretch" justify="between" gap="lg">
          <Flex
            align="stretch"
            className={styles.contactInfo}
            orientation="column"
            gap="sm"
            paddingX="lg"
          >
            <Body
              className={styles.contactTitle}
              text={"Contact Us"} />
            <Body text={"610-922-1070"} />
            <Body text={`customerservice@${props.domain}.com`} />
            <Address
              address="PO Box 460"
              city="Newtown Square"
              state="PA"
              zipcode="19073-0460"
            />
          </Flex>

          <Flex orientation="column">
            <Body dark text={"Disclosures"} paddingX="lg" />
            <a
              href={"/docs/esign.pdf"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                className={styles.docLink}
                paddingX="lg"
                text="E Sign"
                variant="link"
              />
            </a>
            <a
              href={"/docs/credit_authorization.pdf"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                className={styles.docLink}
                paddingX="lg"
                text="Credit Authorization"
                variant="link"
              />
            </a>
            <a
              href={"/docs/privacy.pdf"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                className={styles.docLink}
                paddingX="lg"
                variant="link"
                text="Privacy Statement"
              />
            </a>
          </Flex>
        </Flex>
      </FlexItem>
    </Flex>
  </footer>
);

export default Footer;
