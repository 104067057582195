import styles from '../styles/Opportunity.module.css'

import Title from './playbook/Title'
import Flex from './playbook/Flex'

type OpportunityProps = {
  image: () => JSX.Element
  title: string
}

const Opportunity = (props: OpportunityProps) => {
  const { image, title } = props;
  return (
    <Flex orientation="column" align="center"
      className={styles.opportunityCard}
      padding="lg">
      {image()}
      <Title tag="h5" size={4} text={title} marginTop="sm"
        className={styles.opportunityCardTitle}
      />
    </Flex>
  );
};

export default Opportunity;
