const IntegratedSVG = (): JSX.Element => (
  <svg
    width="132px"
    height="131px"
    viewBox="0 0 132 131"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1028.000000, -1311.000000)">
        <g transform="translate(1028.000000, 1311.000000)">
          <path
            d="M66.5,64 C72.8397436,64 78,69.1602564 78,75.5 C78,81.8397436 72.8397436,87 66.5,87 C60.1602564,87 55,81.8397436 55,75.5 C55,69.1602564 60.1602564,64 66.5,64 Z M66.5,68.6 C62.7625,68.6 59.6,71.61875 59.6,75.5 C59.6,79.38125 62.61875,82.4 66.5,82.4 C70.38125,82.4 73.4,79.38125 73.4,75.5 C73.4,71.61875 70.2375,68.6 66.5,68.6 Z"
            id="Combined-Shape"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            d="M86,109.113879 L86,131 L77.8571429,131 C76.7142857,131 75.7142857,129.978648 75.7142857,128.811388 C75.7142857,127.644128 76.7142857,126.622776 77.8571429,126.622776 L81.7142857,126.622776 L81.7142857,109.113879 C81.7142857,100.94306 75.2857143,94.3772242 67.2857143,94.3772242 L64.5714286,94.3772242 C56.5714286,94.3772242 50.1428571,100.94306 50.1428571,109.113879 L50.1428571,126.622776 L54,126.622776 C55.1428571,126.622776 56.1428571,127.644128 56.1428571,128.811388 C56.1428571,129.978648 55.1428571,131 54,131 L46,131 L46,109.113879 C46,98.6085409 54.4285714,90 64.7142857,90 L67.4285714,90 C77.7142857,90 86,98.6085409 86,109.113879 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            d="M56.0588235,106 L61,106 L61,128.819767 C61,129.982558 60.0392157,131 58.9411765,131 C57.8431373,131 56.8823529,129.982558 56.8823529,128.819767 L56.8823529,110.360465 L56.0588235,110.360465 C54.9607843,110.360465 54,109.343023 54,108.180233 C54,107.017442 54.9607843,106 56.0588235,106 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            d="M75.9411765,110.360465 L75.1176471,110.360465 L75.1176471,128.819767 C75.1176471,129.982558 74.1568627,131 73.0588235,131 C71.9607843,131 71,129.982558 71,128.819767 L71,106 L75.9411765,106 C77.0392157,106 78,107.017442 78,108.180233 C78,109.343023 77.0392157,110.360465 75.9411765,110.360465 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            d="M20.5,1 C26.8397436,1 32,6.16025641 32,12.5 C32,18.8397436 26.8397436,24 20.5,24 C14.1602564,24 9,18.8397436 9,12.5 C9,6.16025641 14.1602564,1 20.5,1 Z M20.5,5.6 C16.7625,5.6 13.6,8.61875 13.6,12.5 C13.6,16.38125 16.61875,19.4 20.5,19.4 C24.38125,19.4 27.4,16.38125 27.4,12.5 C27.4,8.61875 24.2375,5.6 20.5,5.6 Z"
            id="Combined-Shape"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            d="M21.7402135,30.3772242 L18.9679715,30.3772242 C10.797153,30.3772242 4.23131673,36.9430605 4.23131673,45.113879 L4.23131673,62.6227758 L8.17081851,62.6227758 C9.33807829,62.6227758 10.3594306,63.6441281 10.3594306,64.8113879 C10.3594306,65.9786477 9.33807829,67 8.17081851,67 L0,67 L0,45.113879 C0,34.6085409 8.60854093,26 19.113879,26 L21.886121,26 C32.3914591,26 41,34.6085409 41,45.113879 L41,67 L32.683274,67 C31.5160142,67 30.4946619,65.9786477 30.4946619,64.8113879 C30.4946619,63.6441281 31.5160142,62.6227758 32.683274,62.6227758 L36.6227758,62.6227758 L36.6227758,45.113879 C36.4768683,36.9430605 29.911032,30.3772242 21.7402135,30.3772242 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            d="M11.0588235,43 L16,43 L16,65.8197674 C16,66.9825581 15.0392157,68 13.9411765,68 C12.8431373,68 11.8823529,66.9825581 11.8823529,65.8197674 L11.8823529,47.3604651 L11.0588235,47.3604651 C9.96078431,47.3604651 9,46.3430233 9,45.1802326 C9,44.0174419 9.96078431,43 11.0588235,43 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            d="M29.9411765,47.3604651 L29.1176471,47.3604651 L29.1176471,65.8197674 C29.1176471,66.9825581 28.1568627,68 27.0588235,68 C25.9607843,68 25,66.9825581 25,65.8197674 L25,43 L29.9411765,43 C31.0392157,43 32,44.0174419 32,45.1802326 C32,46.3430233 31.0392157,47.3604651 29.9411765,47.3604651 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            d="M111.5,2 C117.839744,2 123,7.16025641 123,13.5 C123,19.8397436 117.839744,25 111.5,25 C105.160256,25 100,19.8397436 100,13.5 C100,7.16025641 105.160256,2 111.5,2 Z M111.5,6.6 C107.7625,6.6 104.6,9.61875 104.6,13.5 C104.6,17.38125 107.61875,20.4 111.5,20.4 C115.38125,20.4 118.4,17.38125 118.4,13.5 C118.4,9.61875 115.2375,6.6 111.5,6.6 Z"
            id="Combined-Shape"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            d="M132,47.113879 L132,69 L123.683274,69 C122.516014,69 121.494662,67.9786477 121.494662,66.8113879 C121.494662,65.6441281 122.516014,64.6227758 123.683274,64.6227758 L127.622776,64.6227758 L127.622776,47.113879 C127.622776,38.9430605 121.05694,32.3772242 112.886121,32.3772242 L110.113879,32.3772242 C101.94306,32.3772242 95.3772242,38.9430605 95.3772242,47.113879 L95.3772242,64.6227758 L99.316726,64.6227758 C100.483986,64.6227758 101.505338,65.6441281 101.505338,66.8113879 C101.505338,67.9786477 100.483986,69 99.316726,69 L91,69 L91,47.113879 C91,36.6085409 99.6085409,28 110.113879,28 L112.886121,28 C123.537367,28 132,36.6085409 132,47.113879 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            d="M102.882353,65.8197674 L102.882353,47.3604651 L102.058824,47.3604651 C100.960784,47.3604651 100,46.3430233 100,45.1802326 C100,44.0174419 100.960784,43 102.058824,43 L107,43 L107,65.8197674 C107,66.9825581 106.039216,68 104.941176,68 C103.843137,68 102.882353,66.9825581 102.882353,65.8197674 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            d="M120.941176,47.3604651 L120.117647,47.3604651 L120.117647,65.8197674 C120.117647,66.9825581 119.156863,68 118.058824,68 C116.960784,68 116,66.9825581 116,65.8197674 L116,43 L120.941176,43 C122.039216,43 123,44.0174419 123,45.1802326 C123,46.3430233 122.039216,47.3604651 120.941176,47.3604651 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            d="M45.6386408,101.985533 C45.1951545,102.710152 44.4560107,103 43.7168669,103 C43.2733806,103 42.9777231,102.855076 42.5342368,102.710152 C40.6124629,101.550761 38.690689,100.246446 36.7689151,98.7972073 C35.8819425,98.0725879 35.7341138,96.623349 36.4732576,95.7538057 C37.2124014,94.8842624 38.690689,94.7393385 39.5776616,95.4639579 C41.203778,96.7682729 42.9777231,97.927664 44.7516682,99.0870551 C45.9342983,99.6667506 46.3777846,100.971066 45.6386408,101.985533 Z"
            id="Path"
            fill="#09ADB9"
          ></path>
          <path
            d="M35.4726498,91.3292293 C36.1983209,92.2102143 36.1983209,93.6785226 35.3275156,94.4126767 C34.892113,94.8531692 34.4567104,95 33.8761736,95 C33.2956367,95 32.7150999,94.7063383 32.2796973,94.2658459 C29.3770131,90.8887368 26.9097316,87.2179661 25.1681211,83.106703 C24.7327185,81.9320564 25.1681211,80.7574097 26.1840605,80.1700864 C27.3451342,79.7295939 28.5062079,80.1700864 29.0867447,81.1979022 C30.683221,84.8686729 32.8602341,88.2457819 35.4726498,91.3292293 Z"
            id="Path"
            fill="#09ADB9"
            fillRule="nonzero"
          ></path>
          <path
            d="M25.5160834,76.8515701 C25.3863819,76.8515701 25.1269789,77 24.9972774,77 C24.0893667,77 23.3111576,76.4062802 23.1814561,75.3672707 C22.66265,73.1408216 22.2735455,70.7659426 22.0141425,68.5394935 C21.8844409,67.352054 22.66265,66.1646145 23.7002622,66.0161846 C24.7378743,65.8677546 25.7754865,66.7583343 25.905188,67.9457738 C26.164591,70.0237929 26.423994,72.101812 26.9428001,74.1798312 C27.2022031,75.2188407 26.5536956,76.5547102 25.5160834,76.8515701 Z"
            id="Path"
            fill="#09ADB9"
            fillRule="nonzero"
          ></path>
          <path
            d="M109.952881,70.6062148 C109.586088,73.0044391 109.219296,75.2527745 108.607975,77.5011098 C108.363447,78.4004439 107.629862,79 106.896277,79 C106.651748,79 106.529484,79 106.284956,78.850111 C105.306842,78.4004439 104.817786,77.2013317 105.062314,76.0022196 C105.551371,74.0536623 106.040428,71.955216 106.284956,69.8567697 C106.40722,68.6576575 107.385333,67.7583234 108.363447,68.0581014 C109.463824,68.2079904 110.197409,69.4071026 109.952881,70.6062148 Z"
            id="Path"
            fill="#09ADB9"
            fillRule="nonzero"
          ></path>
          <path
            d="M106.825544,84.1128878 C104.898528,87.7909662 102.675049,91.1747984 99.8586418,94.2643843 C99.413946,94.7057537 98.8210181,95 98.2280903,95 C97.6351625,95 97.1904667,94.8528769 96.7457708,94.4115074 C95.8563791,93.5287686 95.7081471,92.2046604 96.5975388,91.3219216 C99.1174821,88.5265819 101.192729,85.436996 102.971513,82.200287 C103.564441,81.170425 104.898528,80.7290556 105.936152,81.170425 C106.825544,81.6117944 107.27024,82.9359027 106.825544,84.1128878 Z"
            id="Path"
            fill="#09ADB9"
            fillRule="nonzero"
          ></path>
          <path
            d="M95.5238864,95.7797834 C96.2674908,96.6462094 96.1187699,98.0902527 95.2264447,98.8122744 C93.4417944,100.256318 91.5084232,101.555957 89.4263311,102.711191 C89.1288894,102.855596 88.6827268,103 88.2365642,103 C87.4929599,103 86.7493556,102.566787 86.3031931,101.98917 C85.7083096,100.978339 86.0057513,99.6787004 87.0467974,98.9566787 C88.8314477,97.9458484 90.616098,96.6462094 92.2520275,95.3465704 C93.2930735,94.7689531 94.7802821,94.9133574 95.5238864,95.7797834 Z"
            id="Path"
            fill="#09ADB9"
            fillRule="nonzero"
          ></path>
          <path
            d="M37.4669992,31.222175 C36.7198005,30.186029 36.8692402,28.8538412 37.9153184,28.1137369 C39.8580351,26.6335284 41.8007518,25.4493615 43.7434684,24.2651946 C44.7895466,23.6731112 46.1345043,24.1171738 46.7322633,25.1533198 C47.3300223,26.1894658 46.881703,27.5216535 45.8356248,28.1137369 C44.0423479,29.1498829 42.249071,30.3340498 40.4557941,31.5182167 C40.0074748,31.8142584 39.5591556,31.9622793 39.1108364,31.9622793 C38.5130774,32.1103001 37.9153184,31.8142584 37.4669992,31.222175 Z"
            id="Path"
            fill="#09ADB9"
            fillRule="nonzero"
          ></path>
          <path
            d="M88.4263311,25.3637167 C90.3597023,26.5163632 92.2930735,27.8130906 94.2264447,29.2538988 C95.1187699,29.9743028 95.2674908,31.415111 94.5238864,32.2795959 C94.0777239,32.8559192 93.4828404,33 92.7392361,33 C92.2930735,33 91.6981901,32.8559192 91.2520275,32.5677575 C89.616098,31.2710302 87.8314477,30.1183837 86.0467974,28.9657371 C85.0057513,28.3894139 84.7083096,27.0926865 85.3031931,25.94004 C86.0467974,25.0755551 87.3852851,24.6433126 88.4263311,25.3637167 Z"
            id="Path"
            fill="#09ADB9"
            fillRule="nonzero"
          ></path>
          <path
            d="M68.2183908,11.2702703 C70.908046,11.8378378 72.8505747,13.8243243 72.8505747,16.2364865 C72.8505747,17.2297297 71.954023,17.9391892 71.0574713,17.9391892 C70.1609195,17.9391892 69.2643678,17.0878378 69.2643678,16.2364865 C69.2643678,15.527027 68.0689655,14.6756757 66.4252874,14.6756757 C64.7816092,14.6756757 63.5862069,15.527027 63.5862069,16.2364865 C63.5862069,16.9459459 64.7816092,17.7972973 66.4252874,17.7972973 C70.0114943,17.7972973 73,20.0675676 73,22.9054054 C73,25.1756757 71.0574713,27.1621622 68.3678161,27.8716216 L68.3678161,28.2972973 C68.3678161,29.2905405 67.4712644,30 66.5747126,30 C65.6781609,30 64.7816092,29.1486486 64.7816092,28.2972973 L64.7816092,27.8716216 C62.091954,27.3040541 60.1494253,25.3175676 60.1494253,22.9054054 C60.1494253,21.9121622 61.045977,21.2027027 61.9425287,21.2027027 C62.8390805,21.2027027 63.7356322,22.0540541 63.7356322,22.9054054 C63.7356322,23.6148649 64.9310345,24.4662162 66.5747126,24.4662162 C68.2183908,24.4662162 69.4137931,23.6148649 69.4137931,22.9054054 C69.4137931,22.1959459 68.2183908,21.3445946 66.5747126,21.3445946 C62.9885057,21.3445946 60,19.0743243 60,16.2364865 C60,13.9662162 61.9425287,11.9797297 64.6321839,11.2702703 L64.6321839,10.7027027 C64.6321839,9.70945946 65.5287356,9 66.4252874,9 C67.3218391,9 68.2183908,9.85135135 68.2183908,10.7027027 L68.2183908,11.2702703 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <path
            d="M63.785199,39.8696748 C62.6957528,39.7393496 61.8786682,38.6967478 62.014849,37.6541461 C62.1510297,36.6115443 63.2404759,35.829593 64.329922,35.9599182 C67.5982605,36.4812191 71.0027798,35.829593 73.9987567,34.2656904 C74.9520221,33.7443895 76.177649,34.0050399 76.7223721,34.9173165 C77.2670952,35.829593 76.9947336,37.00252 76.0414682,37.5238208 C73.1816721,39.0877235 69.9133336,40 66.5088144,40 C65.6917297,40 64.7384643,40 63.785199,39.8696748 Z"
            id="Path"
            fill="#09ADB9"
          ></path>
          <path
            d="M46,19.9093645 L46,19.9093645 C46,15.449342 47.412859,11.268071 50.238577,7.78367843 C50.9450065,6.94742422 52.3578655,6.66867282 53.2055809,7.50492703 C54.0532963,8.20180554 54.3358681,9.59556256 53.4881527,10.4318168 C51.3688642,13.0799551 50.0972911,16.4249719 50.0972911,19.9093645 C50.0972911,21.0243701 49.1082898,22 47.9780026,22 C46.8477154,22 46,21.0243701 46,19.9093645 Z"
            id="Path"
            fill="#09ADB9"
          ></path>
          <path
            d="M55.2776279,5.82640104 C54.7329048,4.79950196 55.0052664,3.47920313 55.9585318,2.74570378 C59.6354125,0.39850587 63.9931972,-0.481693348 68.214801,0.251806 C69.3042472,0.39850587 70.1213318,1.57210483 69.985151,2.74570378 C69.8489703,3.91930274 68.7595241,4.79950196 67.670078,4.65280209 C64.4017395,4.06600261 60.8610395,4.79950196 58.0012433,6.70660026 C57.7288818,6.85330013 57.3203395,7 56.9117972,7 C56.3670741,6.85330013 55.6861702,6.55990039 55.2776279,5.82640104 Z"
            id="Path"
            fill="#09ADB9"
          ></path>
          <path
            d="M83.8807115,18 C85.0109987,18 86,18.8333333 86,20.0833333 L83.8807115,20.0833333 L86,20.0833333 C86,24.5277778 84.4458551,28.6944444 81.761423,32.1666667 C81.3375653,32.7222222 80.7724217,33 80.0659922,33 C79.6421345,33 79.0769909,32.8611111 78.7944191,32.5833333 C77.9467037,31.8888889 77.6641319,30.5 78.5118473,29.6666667 C80.6311358,27.0277778 81.9027089,23.6944444 81.9027089,20.2222222 C81.761423,18.9722222 82.7504243,18 83.8807115,18 Z"
            id="Path"
            fill="#09ADB9"
          ></path>
          <path
            d="M56.8562734,37 C56.4214875,37 56.1316302,36.8571551 55.841773,36.7143101 C51.9287002,34.4287912 48.8851992,31.0005127 47.1460557,26.8580096 C46.7112698,25.7152501 47.2909843,24.5724906 48.3054847,24.1439558 C49.4649136,23.715421 50.6243426,24.2868007 51.0591285,25.2867153 C52.3634861,28.5721488 54.8272727,31.2862026 57.8707737,33.0003418 C58.8852741,33.5717215 59.3200599,34.857326 58.7403454,35.8572405 C58.3055596,36.5714652 57.5809165,37 56.8562734,37 Z"
            id="Path"
            fill="#09ADB9"
          ></path>
          <path
            d="M73.35686,3.11564105 C73.9317747,2.11334572 75.2253327,1.68379057 76.2314334,2.25653076 C80.1121075,4.54749153 83.1304096,7.98393267 84.8551536,12.136299 C85.2863396,13.2817794 84.7114249,14.4272598 83.7053242,14.856815 C83.4178669,15 83.1304096,15 82.8429522,15 C81.9805802,15 81.1182082,14.4272598 80.8307508,13.7113346 C79.5371928,10.4180785 77.0938054,7.69756257 74.0755033,5.979342 C73.0694027,5.54978686 72.6382166,4.26112143 73.35686,3.11564105 Z"
            id="Path"
            fill="#09ADB9"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default IntegratedSVG;
