import styles from '../styles/TitleBorder.module.css'

type TitleBorderProps = {
  children: JSX.Element
}

const TitleBorder = (props: TitleBorderProps) => (
  <div className={styles.titleBorder}>{props.children}</div>
)

export default TitleBorder;
