
import Opportunity from "./Opportunity"
import TitleBorder from "./TitleBorder"

import OpportunitySVG from "./svgs/opportunity-svg.js"
import IntegratedSVG from "./svgs/integrated-solutions-svg.js"
import PriceTagSVG from "./svgs/price-tag-svg.js"
import TimeFlyingSVG from "./svgs/time-flying-svg.js"
import ApplicationSVG from "./svgs/application-svg.js"

import styles from '../styles/Opportunities.module.css'

import Title from './playbook/Title'
import Flex from './playbook/Flex'

type OpportunitiesProps = {
  domain: string
}

const opportunities = [
  {
    title: "Higher Approvals + Better Credit",
    image: ApplicationSVG,
  },
  {
    title: "Frictionless Process",
    image: TimeFlyingSVG,
  },
  {
    title: "Improved Pricing",
    image: PriceTagSVG,
  },
  {
    title: "Integrated Financial Solutions",
    image: IntegratedSVG,
  }
];

const Opportunities = (props: OpportunitiesProps) => {
  return (
    <section
      className={styles.opportunity}
    >
      <Flex paddingX="md" paddingTop="xl" orientation="column" justify="center" align="center">
        <TitleBorder>
          {[OpportunitySVG()]}
        </TitleBorder>
      </Flex>
      <Flex paddingX="md" paddingY="xl" orientation="column" justify="center" align="center">
        <div className="w-45">
          <Title
            className={styles.sectionLead}
            size={3}
            tag="h3"
            text={`Stream ${props.domain == "streaminnovations" ? "Innovations" : "Financial"} will work closely with Power to craft customized financial solutions that enhance Power’s long term customer relationships.`}
          />
        </div>
      </Flex>
      <Flex paddingX="md" paddingY="lg" orientation="column" align="center">
        <Flex wrap justify="center">
          {opportunities.map((opportunity) => (
            <Opportunity
              image={opportunity.image}
              title={opportunity.title}
              key={opportunity.title}
            />
          ))}
        </Flex>
      </Flex>
      <Flex paddingX="md" paddingY="xl" marginBottom="xl" justify="center" align="center">
        <div className="w-45">
          <Title
            className={styles.sectionLead}
            size={3}
            tag="h3"
            text="Having an integrated financing partner will unlock value across multiple dimensions."
          />
        </div>
      </Flex>
    </section>
  );
};

export default Opportunities;
