const ApplicationSVG = (): JSX.Element => (
  <svg
    width="114px"
    height="131px"
    viewBox="0 0 114 131"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-275.000000, -1311.000000)"
        fillRule="nonzero"
      >
        <g transform="translate(275.000000, 1311.000000)">
          <path
            d="M75.9333333,61 L18.0666667,61 C16.9252782,61 16,61.8954305 16,63 C16,64.1045695 16.9252782,65 18.0666667,65 L75.9333333,65 C77.0747218,65 78,64.1045695 78,63 C78,61.8954305 77.0747218,61 75.9333333,61 Z"
            id="Path"
            fill="#000000"
          ></path>
          <path
            d="M78,73 C78,71.8954534 77.0746982,71.0000552 75.9333333,71 L18.0666667,71 C16.9252782,71 16,71.8954305 16,73 C16,74.1045695 16.9252782,75 18.0666667,75 L75.9333333,75 C77.0746982,74.9999448 78,74.1045466 78,73 Z"
            id="Path"
            fill="#000000"
          ></path>
          <path
            d="M47,101 L19,101 C17.8954305,101 17,101.895431 17,103 C17,104.104569 17.8954305,105 19,105 L47,105 C48.1045695,105 49,104.104569 49,103 C49,101.895431 48.1045695,101 47,101 Z"
            id="Path"
            fill="#000000"
          ></path>
          <path
            d="M47,111 L19,111 C17.8954305,111 17,111.895431 17,113 C17,114.104569 17.8954305,115 19,115 L47,115 C48.1045695,115 49,114.104569 49,113 C49,111.895431 48.1045695,111 47,111 Z"
            id="Path"
            fill="#000000"
          ></path>
          <path
            d="M25,47.7677455 L25,50.8672832 C25,53.1497196 26.8297928,55 29.0869565,55 L67.9130435,55 C70.1702072,55 72,53.1497196 72,50.8672832 L72,47.7677455 C71.9970713,39.8216245 68.0657073,32.4031012 61.5243028,28 C60.7982508,29.1854176 59.9181345,30.2669277 58.9075267,31.2155637 C64.5145669,34.8093345 67.9125907,41.0549181 67.9130435,47.7677455 L67.9130435,50.8672832 L29.0869565,50.8672832 L29.0869565,47.7677455 C29.0874071,41.0549201 32.4854268,34.809337 38.092463,31.2155637 C37.0818729,30.2669109 36.2017587,29.1854033 35.475687,28 C28.9342864,32.4031036 25.0029264,39.8216264 25,47.7677455 L25,47.7677455 Z"
            id="Path"
            fill="#09ADB9"
          ></path>
          <path
            d="M48,30 C54.0751322,30 59,25.0751322 59,19 C59,12.9248678 54.0751322,8 48,8 C41.9248678,8 37,12.9248678 37,19 C37.0068611,25.072288 41.927712,29.9931389 48,30 Z M48,12 C51.8659932,12 55,15.1340068 55,19 C55,22.8659932 51.8659932,26 48,26 C44.1340068,26 41,22.8659932 41,19 C41,15.1340068 44.1340068,12 48,12 Z"
            id="Shape"
            fill="#09ADB9"
          ></path>
          <path
            d="M66,91 L20.0263852,91 C18.9072436,91 18,91.8954305 18,93 C18,94.1045695 18.9072436,95 20.0263852,95 L63.8513528,95 C64.4567891,93.6115105 65.1753619,92.2737891 66,91 Z"
            id="Path"
            fill="#000000"
          ></path>
          <path
            d="M77,81.03485 C76.8846594,81.0131832 76.7676253,81.0015222 76.6502323,81 L20.0224218,81 C18.9054691,81 18,81.8954305 18,83 C18,84.1045695 18.9054691,85 20.0224218,85 L71.097835,85 C72.8889413,83.4382972 74.8719587,82.1060807 77,81.03485 L77,81.03485 Z"
            id="Path"
            fill="#000000"
          ></path>
          <path
            d="M8.17021277,126.90625 C5.91506713,126.903841 4.0875099,125.072417 4.08510638,122.8125 L4.08510638,8.1875 C4.0875099,5.92758273 5.91506713,4.09615861 8.17021277,4.09375 L87.8297872,4.09375 C90.0849329,4.09615861 91.9124901,5.92758273 91.9148936,8.1875 L91.9148936,77.8624598 C93.2889954,77.9593204 94.6542784,78.1559164 96,78.4507009 L96,8.1875 C95.9948391,3.66781258 92.3399317,0.00517180452 87.8297872,0 L8.17021277,0 C3.66006829,0.00517180452 0.00516088468,3.66781258 0,8.1875 L0,122.8125 C0.00516088468,127.332187 3.66006829,130.994828 8.17021277,131 L75.1608511,131 C73.2503305,129.844952 71.4803548,128.471138 69.8866009,126.90625 L8.17021277,126.90625 Z"
            id="Path"
            fill="#000000"
          ></path>
          <path
            d="M89.5,82 C75.9690236,82 65,92.9690236 65,106.5 C65,120.030976 75.9690236,131 89.5,131 C103.030976,131 114,120.030976 114,106.5 C113.984709,92.9753623 103.024638,82.0152907 89.5,82 L89.5,82 Z M89.5,126.916667 C78.2241864,126.916667 69.0833333,117.775814 69.0833333,106.5 C69.0833333,95.2241864 78.2241864,86.0833333 89.5,86.0833333 C100.775814,86.0833333 109.916667,95.2241864 109.916667,106.5 C109.903952,117.770543 100.770543,126.903952 89.5,126.916667 L89.5,126.916667 Z"
            id="Shape"
            fill="#000000"
          ></path>
          <path
            d="M99.4627246,97.5820497 L84.3327022,112.170905 L78.5383964,106.583848 C77.7278479,105.805269 76.4165757,105.806476 75.607571,106.586547 C74.7985662,107.366618 74.7973185,108.630989 75.6047823,109.412544 L82.8658952,116.413943 C83.2548478,116.789178 83.7825022,117 84.3327074,117 C84.8829126,117 85.410567,116.789178 85.7995196,116.413943 L102.396349,100.410745 C103.202746,99.629016 103.200991,98.365486 102.392426,97.585842 C101.583862,96.806198 100.273462,96.8045062 99.462735,97.5820597 L99.4627246,97.5820497 Z"
            id="Path"
            fill="#09ADB9"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default ApplicationSVG;
