
import styles from '../../styles/Opportunities.module.css'

const OpportunitySVG = () => (
  <svg
    className={styles.opportunitySvg}
    viewBox="0 0 387 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      fontFamily="Venera700, Venera"
      fontSize="36"
      fontWeight="bold"
      letterSpacing="-0.4"
    >
      <g transform="translate(-547.000000, -1217.000000)" fill="#242B42">
        <text>
          <tspan x="545.098" y="1242">
            Opportunity
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

export default OpportunitySVG;
